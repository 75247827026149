html {
	font-size: 62.5%;
	font-family: "Times New Roman", Times, serif;
	overflow-x: hidden;
	color: black;
}
html,
body {
	margin: 0;
}
.button {
	cursor: pointer;
}
.BlockHeader {
	margin: 0;
	text-align: center;
	font-size: 300%;
}
.Paragraph {
	text-indent: 40px;
	margin-top: 0px;
	margin-bottom: 0px;
}
#Heading {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	height: 1.5em;
	font-size: 400%;
	background-color: black;
	color: white;
}
/* #NavButton {
	/* Make image have a fixed position on the right side of the screen
	position: fixed;
	width: 5rem;
	height: auto;
	top: 4px;
	right: 1%;

	/* Make image have a box only on the circular image
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
} */
/* Prevent scrolling when a popup opens*/
.PopupOpen {
	position: fixed;
	width: 100vw;
	height: 100vh;
}
#PopupBackground {
	/* Used in place of a filter because filter was giving me some trouble */
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 110vh; /* Larger than 100 to improve mobile rendering */
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	cursor: initial;
}
